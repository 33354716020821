import React, { useState } from "react";
// eslint-disable-next-line
import styles from "./RmhScreenWrapper.module.css";

import { images } from "../../../images";

import { AnimatePresence, motion, useCycle } from "framer-motion";

import { Outlet, useNavigate } from "react-router-dom";

import {
  AnimationLayout,
  SortBidsModal,
  TimeSelectModal,
} from "../../../components";
import { useNavigateOnEvent } from "../../../hooks";
import {
  ADV_POST_A_RIDE_TO_ROUTE,
  RIDER_EXTRAS_EDIT_PROFILE,
  RIDER_EXTRAS_HELP,
  RIDER_EXTRAS_PAST_RIDES,
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
  RIDER_SCHEDULED_RIDE,
} from "../../../constants/routeConstants";
import { useDispatch, useSelector } from "react-redux";
import { useLogoutHandler } from "../../../hooks";
import {
  ACCESS_TOKEN_COOKIE_NAME,
  GLOBAL_VERSION_CONSTANT,
  POINTS_CONSTANT,
  callToWhatsApp,
  navigateToWhatsApp,
} from "../../../constants/storageConstants";
import { uiElementActions } from "../../../store/slice";
import { getMobileOperatingSystem } from "../../../lib";
import {
  BASE_URL,
  POST_POINTS,
  RIDER_PAST_RIDES,
} from "../../../constants/apiConstants";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const RmhScreenWrapper = () => {
  const navigate = useNavigate();
  const isTimeSelectModalOpen = useSelector(
    (state) => state.ui.isTimeSelectModalOpen
  );
  const isSortBidsModalOpen = useSelector(
    (state) => state.ui.isSortBidsModalOpen
  );
  const navigateToPostRideTo = useNavigateOnEvent(
    RIDER_HOME_POST_A_RIDE__TO_ROUTE
  );
  const navigateToExtrasPastRides = useNavigateOnEvent(RIDER_EXTRAS_PAST_RIDES);
  const navigateToExtrasHelp = useNavigateOnEvent(RIDER_EXTRAS_HELP);
  const navigateToSchduledRide = useNavigateOnEvent(RIDER_SCHEDULED_RIDE);

  const logoutFunction = useLogoutHandler();

  const naviateToFirst = () => {
    cycleOpen();
    navigateToPostRideTo();
  };

  const onClickScheduledRide = () => {
    cycleOpen();
    // navigateToSchduledRide();
    // callToWhatsApp();
    navigate(`${ADV_POST_A_RIDE_TO_ROUTE}`);
  };

  const naviateToSecond = () => {
    cycleOpen();
    navigateToExtrasPastRides();
  };

  const naviateToThird = () => {
    cycleOpen();
    navigateToExtrasHelp();
  };

  const dispatch = useDispatch();

  const logsDialogBox = () => {
    // console.log("Clicked");
    cycleOpen();
    dispatch(uiElementActions.openLogsDownloadDialogBox());
  };

  const userAgentOs = getMobileOperatingSystem();
  const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);

  const fetchPastRides = async (url) => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onReferFriend = async () => {
    const spotRides = await fetchPastRides(`${BASE_URL}${RIDER_PAST_RIDES}`);
    // const advRides = await fetchPastRides(`${BASE_URL}${RIDER_ADV_PAST_RIDES}`);

    if (spotRides.length > 0) {
      const { user_id } = JSON.parse(
        localStorage.getItem("_user_driver_rider_")
      );
      postPoints(user_id, POINTS_CONSTANT);
      navigateToWhatsApp();
    } else {
      toast.warn("You have no past rides, please complete your first ride");
    }
  };

  //Posting Points
  const postPoints = async (userId, points) => {
    try {
      const response = await fetch(`${BASE_URL}${POST_POINTS}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customer: userId,
          points: points,
        }),
      });

      // Check if the request was successful (status 200-299)
      if (response.ok) {
        const responseData = await response.json(); // Parse response data as JSON
        console.log("Points posted successfully:", responseData);

        return responseData;
      } else {
        console.error("Error posting points:", response.statusText);
        throw new Error("Failed to post points");
      }
    } catch (error) {
      console.error("Error:", error.message);
      throw error;
    }
  };

  const hamMenuItems = [
    {
      imgSrc: images.car,
      alt: "car",
      text: "Book your ride",
      onClickNavigation: naviateToFirst,
    },
    {
      imgSrc: images.clock,
      alt: "clock",
      text: "Advance ride",
      onClickNavigation: onClickScheduledRide,
      //os: "iOS",
    },
    {
      imgSrc: images.clockThin,
      alt: "clock",
      text: "Your Rides",
      onClickNavigation: naviateToSecond,
    },
    {
      imgSrc: images.headset,
      alt: "help",
      text: "Help",
      onClickNavigation: naviateToThird,
    },
    {
      imgSrc: images.whatsapp,
      alt: "whatsapp",
      text: "Claim Cashback",
      onClickNavigation: callToWhatsApp,
    },
    {
      imgSrc: images.megaPhone,
      alt: "Refer Your Friend",
      text: "Refer Your Friend",
      onClickNavigation: onReferFriend,
    },
    {
      imgSrc: images.logOff,
      alt: "log out",
      text: "Log Off",
      onClickNavigation: logoutFunction,
    },
  ];

  const [open, cycleOpen] = useCycle(false, true);

  const navigateToEditProfile = useNavigateOnEvent(RIDER_EXTRAS_EDIT_PROFILE);

  return (
    <>
      {/* rrelative */}
      <AnimationLayout>
        <div className={styles.rmhScreenWrapper}>
          <AnimatePresence>
            {open && (
              <>
                <motion.aside
                  initial={{ width: "0%", opacity: 0 }}
                  animate={{ width: "70%", opacity: 1 }}
                  exit={{
                    width: "0%",
                    transition: { delay: 0, duration: 0.3 },
                    opacity: 0,
                  }}
                  className={styles.hamburgerMenu}
                >
                  <button onClick={cycleOpen} className={styles.crossButton}>
                    X
                  </button>

                  <motion.ul
                    initial={{ opacity: 0, visibility: "hidden" }}
                    animate={{ opacity: 1, visibility: "visible" }}
                    exit={{
                      opacity: 0,
                      transition: { delay: 0, duration: 0.1 },
                    }}
                    className={styles.hamburgerList}
                  >
                    {hamMenuItems.map(
                      (eachItem) =>
                        userAgentOs !== eachItem?.os && (
                          <li
                            key={eachItem.text}
                            className={styles.liClass}
                            onClick={eachItem.onClickNavigation}
                          >
                            <img
                              className={`${
                                eachItem.alt === "whatsapp"
                                  ? styles.liClassWa
                                  : styles.liClassImg
                              }`}
                              src={eachItem.imgSrc}
                              alt={eachItem.alt}
                            />
                            <span>{eachItem.text}</span>
                          </li>
                        )
                    )}
                  </motion.ul>

                  <motion.p
                    initial={{ opacity: 0, visibility: "hidden" }}
                    animate={{ opacity: 1, visibility: "visible" }}
                    exit={{
                      opacity: 0,
                      transition: { delay: 0, duration: 0.1 },
                    }}
                    className={styles.termsOfService}
                    onClick={() => {}}
                    onDoubleClick={logsDialogBox}
                  >
                    {`Version - ${GLOBAL_VERSION_CONSTANT}`}
                  </motion.p>
                </motion.aside>
                <motion.aside
                  onClick={cycleOpen}
                  className={styles.hamburgerSideClosing}
                ></motion.aside>
              </>
            )}
          </AnimatePresence>
          {/* absolute z-10 */}
          {/* absolute z-5 */}
          <div className={styles.appbar}>
            <>
              <img
                onClick={cycleOpen}
                className={styles.hamburgerIcon}
                src={images.hamburgerIcon}
                alt="menu icon"
              />

              <img
                className={styles.logoWithTextRow}
                src={images.logoWithTextRow}
                alt="taxiwars logo"
              />
              <img
                onClick={navigateToEditProfile}
                className={styles.userGeneric}
                src={images.userGeneric}
                alt="profile pic"
              />
            </>
          </div>
          <div className={styles.content}>
            <Outlet />
          </div>
          <div className={styles.bottomModal}>
            {/* {isTimeSelectModalOpen && <TimeSelectModal />}
            {isSortBidsModalOpen && <SortBidsModal />} */}
            <div></div>
          </div>
        </div>
      </AnimationLayout>
    </>
  );
};

export default RmhScreenWrapper;
