import { createSlice } from "@reduxjs/toolkit";

const campaignDialogBoxSlice = createSlice({
  name: "campaignDialogBox",
  initialState: {
    isCampaignPopupModalOpen: false,
  },
  reducers: {
    openCampaignPopup(state) {
      state.isCampaignPopupModalOpen = true;
    },
    closeCampaignPopup(state) {
      state.isCampaignPopupModalOpen = false;
    },
  },
});

export const campaignDialogBoxActions = campaignDialogBoxSlice.actions;
export default campaignDialogBoxSlice;
