import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BookOnWhatsApp,
  BookRideWithBack,
  // BottomMessagePara,
  Button,
  ErrorInNumber,
  GoogleMapComponentPolyLineOriginDestination,
  GridFromToTextBox,
  HorizontalLine,
  LoadingSpinner,
  SelectTimeDiv,
} from "../../../components";
import {
  BASE_URL,
  CODE_200,
  RIDER_CREATE_RIDE,
} from "../../../constants/apiConstants";
import {
  RIDER_HOME_POST_A_RIDE__TO_ROUTE,
  RIDER_POST_A_RIDE_FROM_TO_ROUTE,
  RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE,
} from "../../../constants/routeConstants";
import {
  useLogoutHandler,
  useNavigateOnEvent,
  useRedirectAccToRide,
  useReverseGeocoding,
} from "../../../hooks";
import {
  riderCreatedRideActions,
  riderPostRideActions,
} from "../../../store/slice";
// eslint-disable-next-line
import styles from "./PostRideFromToMapSelection.module.css";

import { ACCESS_TOKEN_COOKIE_NAME } from "../../../constants/storageConstants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { duration } from "@mui/material";

const PostRideFromToMapSelection = () => {
  useRedirectAccToRide();

  const logoutFunction = useLogoutHandler();
  const dispatch = useDispatch();

  const [originAdTextState, setOriginAdTextState] = useState("");

  const [tapBookRideCount, setTapBookRideCount] = useState(0);

  const [scheduleTime, setScheduleTime] = useState(null);
  const [scheduleRideBool, setScheduleRideBool] = useState(false);

  const [distanceMetres, setDistanceMetres] = useState("");

  const [isBookRideDataLoadedStatus, setIsBookRideDataLoadedStatus] =
    useState("LOADING");

  const navigateBack = useNavigateOnEvent(-1);
  // const navigateBackToHome = useNavigateOnEvent(-2);
  // * CODE_REFACTOR_SEPERATION_OF_CONCERN
  const navigate = useNavigate();
  // const navigateToWaitingForBid = useNavigateOnEvent(
  //   RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE
  // );
  const navigateToPostRideTo = useNavigateOnEvent(
    RIDER_HOME_POST_A_RIDE__TO_ROUTE,
    true
  );
  const navigateToPostRideFromTo = useNavigateOnEvent(
    RIDER_POST_A_RIDE_FROM_TO_ROUTE,
    true
  );
  const origin = useSelector((state) => state.riderPostRide.inputOrigin);
  const destination = useSelector(
    (state) => state.riderPostRide.inputDestination
  );

  const originAddress = useSelector((state) => state.riderPostRide.inputOrigin);

  const originAddressTextFromReverseGeoCoding = useReverseGeocoding(
    originAddress.latitude || "",
    originAddress.longitude || ""
  );

  const destinationAddressText = useSelector(
    (state) => state.riderPostRide.inputDestinationText
  );

  const originAddressText = useSelector(
    (state) => state.riderPostRide.inputOriginText
  );

  useEffect(() => {
    // console.log(
    //   "originAddressTextFromReverseGeoCoding",
    //   originAddressTextFromReverseGeoCoding
    // );

    // console.log(
    //   "originAddressText.mainAddress originAddressText.subAddress",
    //   `${originAddressText.mainAddress}${originAddressText.subAddress}`
    // );
    if (`${originAddressText.mainAddress}${originAddressText.subAddress}`) {
      setOriginAdTextState(
        `${originAddressText.mainAddress}${" "}${
          originAddressText.subAddress
        }`.slice?.(0, 95)
      );
      return;
    }
    // console.log("nothing happnened");

    if (originAddressTextFromReverseGeoCoding) {
      setOriginAdTextState(
        originAddressTextFromReverseGeoCoding?.slice?.(0, 95)
      );
    }
  }, [
    originAddressTextFromReverseGeoCoding,
    originAddressText.mainAddress,
    originAddressText.subAddress,
  ]);

  // const access = Cookies.get("access");
  // const { loading, error, data, fetchData } = useAPI(`${BASE_URL}ride/`, {
  // method: "POST",
  // headers: {
  // "Content-Type": "application/json",
  // Authorization: `Bearer ${access}`,
  // },
  // body: JSON.stringify({
  // pickup_address: "pune",
  // drop_address: "thane",
  // ride_time: "start_time_time",
  // }),
  // });

  const [notAuthoorisedError, setNotAuthoorisedError] = useState(false);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const resetInputsForPostRide = () => {
    dispatch(
      riderPostRideActions.updateDestinationAddressText({
        mainAddress: "",
        subAddress: "",
      })
    );
    dispatch(
      riderPostRideActions.updateOriginAddress({
        latitude: "",
        longitude: "",
      })
    );
    // dispatch(riderPostRideActions.updateTime("now"));
  };

  // TODO ADD initial state check for empty location->  redirct to rider home post ride to
  // useEffect(() => {
  //   if (
  //     (originAddressText.mainAddress && originAddress.subAddress) ||
  //     originAddressTextFromReverseGeoCoding ||
  //     destinationAddressText
  //   ) {
  //     // navigateToPostRideTo();
  //     console.log("Empty have");
  //     console.log(
  //       originAddressText,
  //       originAddressTextFromReverseGeoCoding,
  //       destinationAddressText
  //     );
  //   }
  // }, []);

  useEffect(() => {
    if (
      origin &&
      origin.latitude &&
      origin.longitude &&
      destination &&
      destination.latitude &&
      destination.longitude &&
      distance
    ) {
      setIsBookRideDataLoadedStatus("COMPLETE");
    } else {
      setIsBookRideDataLoadedStatus("LOADING");
    }
  }, [destination, distance, origin]);

  const confirmationDialogOpen = () => {
    setIsConfirmationDialogOpen(true);
  };

  const bookRideButtonHandler = () => {
    if (tapBookRideCount === 1) return;
    setTapBookRideCount(1);
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE_NAME);
    // Check whether we have the location or not
    // else redirect them to post ride to page(home for driver)
    toast.success("Creating your ride", {
      toastId: "book",
      autoClose: false,
      icon: <LoadingSpinner />,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
    if (
      origin &&
      origin.latitude &&
      origin.longitude &&
      destination &&
      destination.latitude &&
      destination.longitude &&
      distance
    ) {
      // console.log("The data we are sending before fetch request - ", {
      //   pickup_address: `${origin.latitude},${origin.longitude}`,
      //   drop_address: `${destination.latitude},${destination.longitude}`,
      //   pickup_address_text: originAdTextState,
      //   drop_address_text: `${destinationAddressText.mainAddress}${" "}${
      //     destinationAddressText.subAddress
      //   }`,
      //   ride_time: new Date().toLocaleTimeString("en-US", {
      //     hour12: false,
      //   }),
      //   distance: distance,
      // });
      fetch(`${BASE_URL}${RIDER_CREATE_RIDE}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        method: "POST",
        body: JSON.stringify({
          pickup_address: `${origin.latitude},${origin.longitude}`,
          drop_address: `${destination.latitude},${destination.longitude}`,
          pickup_address_text: originAdTextState,
          drop_address_text: `${destinationAddressText.mainAddress}${" "}${
            destinationAddressText.subAddress
          }`.slice(0, 95),
          ride_time: new Date().toLocaleTimeString("en-US", { hour12: false }),
          // scheduled_time: new Date().toLocaleTimeString("en-US", {
          //   hour12: false,
          // }),
          schedule_status: scheduleRideBool,
          schedule_time: scheduleTime,
          distance: distance,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            // console.log("Response after creating a ride - ", response);
            return response.json();
          } else if (response.status === 401) {
            console.log("401 Response after creating a ride- ");
            setNotAuthoorisedError(true);
            // toast
            toast.error("Please Login");
            // log off
            // const logoutFunction = useLogoutHandler();
            logoutFunction();
          }
          throw response;
        })
        .then((data) => {
          // console.log("Data after creating a ride ", data);
          // toast.success("Ride Booked", {
          //   autoClose: 10000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          //   theme: "dark",
          // });
          // console.log("The data we are sending - ", {
          //   pickup_address: `${origin.latitude},${origin.longitude}`,
          //   drop_address: `${destination.latitude},${destination.longitude}`,
          //   pickup_address_text: originAdTextState,
          //   drop_address_text: `${destinationAddressText.mainAddress}${" "}${
          //     destinationAddressText.subAddress
          //   }`,
          //   ride_time: new Date().toLocaleTimeString("en-US", {
          //     hour12: false,
          //   }),
          //   distance: distance,
          // });
          // return;
          //data we get back after creating a ride
          if (data === "No Drivers around you") {
            toast.error("No drivers found ! Try after sometime", {
              toastId: "error",
              // autoClose: 10000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            // console.log(data);
            //navigation
            return;
          } else if (data && data?.Code === CODE_200) {
            // * CODE_REFACTOR_SEPERATION_OF_CONCERN
            // dispatch(riderCreatedRideActions.setCreatedRideId(data?.ride_id));
            // dispatch(riderPostRideActions.clearPostRide());
            toast.dismiss("loading");
            toast.dismiss("error");
            toast.update("book", {
              render: "Your ride has been created",
              type: toast.TYPE.SUCCESS,
              autoClose: 20000,
              closeButton: true,
              icon: null,
              // transition: Slide,
              position: toast.POSITION.TOP_CENTER,
            });
            // toast.success("Ride created", {
            //   toastId: "book",
            //   autoClose: 10000,
            //   hideProgressBar: false,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            //   theme: "colored",
            // });
            // console.log("Code else block");
            // * CODE_REFACTOR_SEPERATION_OF_CONCERN
            // clearing create ride actions
            dispatch(riderPostRideActions.clearPostRide());
            navigate(
              `${RIDER_WAITING_FOR_BID_CONFIRM_BID_ROUTE}?rideId=${data?.ride_id}`
            );
            // navigateToWaitingForBid();

            return;
          }
        })
        .catch((e) => {
          // setIsNetworkError(true);
          console.log("Error", e);
          toast.update("book", {
            render: "Ride not booked",
            type: toast.TYPE.ERROR,
            autoClose: 20000,
            closeButton: true,
            icon: null,
            // transition: Slide,
            position: toast.POSITION.TOP_CENTER,
          });
          setTapBookRideCount(0);
        })
        .finally(() => {
          // setIsLoadingError(false);
        });

      //navigating and bypassing the create ride check
      // navigateToWaitingForBid();
    }
  };
  const cancelButtonHandler = () => {
    // reset inputs etc
    resetInputsForPostRide();
    // navigateBackToHome();
    navigateToPostRideTo();
    // call success  then load to live bids
  };

  const distanceAndTimeLiftUpHandler = (distance, duration, distanceMetres) => {
    // console.log("Distance", distance, "Duration", duration);
    setDistance(distance);
    setDistanceMetres(distanceMetres);
    setDuration(duration);
  };

  const destinationInputOnClickHandler = () => {
    //go the the screen where we can select the destination
    // which the home screen Post Ride To
    navigateToPostRideTo();
  };

  const originInputOnClickHandler = () => {
    //go the the screen where we can select the origin
    // which is the Post Ride From To screen
    navigateToPostRideFromTo();
  };

  const onScheduleTimeAccept = (time) => {
    console.log(time);
    setScheduleTime(time);
    setScheduleRideBool(true);
  };

  const onCancelResetSchedule = () => {
    console.log("schedule cancelled");
    setScheduleTime(null);
    setScheduleRideBool(false);
  };
  // console.log(scheduleRideBool);
  // console.log(scheduleTime);

  const bookWithWhatsApp = () => {
    const pickup_address = `${origin.latitude},${origin.longitude}`;
    const drop_address = `${destination.latitude},${destination.longitude}`;
    const pickup_address_text = originAdTextState;
    const drop_address_text = `${destinationAddressText.mainAddress}${" "}${
      destinationAddressText.subAddress
    }`.slice(0, 95);

    // const distance = distance;
    // const duration = duration;

    const fullNum = "+919650145293";
    const testNum = "+919455879127";
    const fullText = `
    Ride Details
    Pickup Address - ${pickup_address_text} (${pickup_address})
    Drop Address - ${drop_address_text} (${drop_address})
    Distance - ${distance}
    Duration - ${duration}
    
    Send to create ride.
    `;

    // const url =
    //   "https://wa.me/" + testNum + "?text=" + encodeURIComponent(fullText);

    const whatsappURL = `https://web.whatsapp.com/send/?phone=${encodeURIComponent(
      fullNum
    )}&text=${encodeURIComponent(fullText)}&type=phone_number&app_absent=0`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <>
      <div className={styles.postRideFromToMapSelection}>
        <ConfirmationDialog
          bookRideButtonHandler={bookRideButtonHandler}
          setIsConfirmationDialogOpen={setIsConfirmationDialogOpen}
          openLogDialogBox={isConfirmationDialogOpen}
          dropAddress={`${destination.latitude}${destination.longitude}`}
          pickupAddressText={originAddressText.subAddress}
          dropAddressText={`${destinationAddressText.mainAddress}${" "}${
            destinationAddressText.subAddress
          }`.slice(0, 95)}
          distance={distance}
          duration={duration}
          distanceInMetres={distanceMetres}
        />

        <BookRideWithBack
          enableBackButton={true}
          backButtonFunction={navigateBack}
        />
        <div className={styles.timeSelectionAlignment}>
          {false && isBookRideDataLoadedStatus === "COMPLETE" && (
            <BookOnWhatsApp onClick={bookWithWhatsApp} />
          )}
          {isBookRideDataLoadedStatus === "LOADING" && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <LoadingSpinner />
              </div>
            </>
          )}
          {false && (
            <SelectTimeDiv
              onScheduleTimeAccept={onScheduleTimeAccept}
              onCancelResetSchedule={onCancelResetSchedule}
            />
          )}
        </div>
        <GridFromToTextBox
          originAddress={originAdTextState}
          originInputDisabled
          destinationAddress={`${destinationAddressText.mainAddress}${" "}${
            destinationAddressText.subAddress
          }`}
          destinationInputDisabled
          originInputOnClick={originInputOnClickHandler}
          destinationInputOnClick={destinationInputOnClickHandler}
        />
        <HorizontalLine />
        <div className={styles.distanceDurationRow}>
          <p>Distance: {distance}</p>
          <p>Duration: {duration}</p>
        </div>
        <GoogleMapComponentPolyLineOriginDestination
          distanceAndTimeLiftUp={distanceAndTimeLiftUpHandler}
        />
        {/* <div></div> */}
        {/* <Button displayText="Cancel" onClickFunction={cancelButtonHandler} /> */}
      </div>
      {notAuthoorisedError && <ErrorInNumber displayText="Unauthorised" />}
      {tapBookRideCount === 0 && isBookRideDataLoadedStatus === "COMPLETE" && (
        <Button displayText="Next" onClickFunction={confirmationDialogOpen} />
      )}
      {isBookRideDataLoadedStatus === "LOADING" && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LoadingSpinner />
          </div>
        </>
      )}
      <div className={styles.bottomMessagePara}>
        This will notify the nearby drivers and you will receive bids for this
        ride.
      </div>
    </>
  );
};

export default PostRideFromToMapSelection;

const ConfirmationDialog = ({
  openLogDialogBox = false,
  bookRideButtonHandler,
  setIsConfirmationDialogOpen,
  pickupAddressText,
  distance,
  duration,
}) => {
  
  const pickUpRegion = pickupAddressText
    ?.split(",")
    [pickupAddressText?.split(",").length - 2]?.trim()
    ?.toLowerCase();

  // Parse distance string to get number value
  const getDistanceValue = (distanceStr) => {
    // Remove 'km' and any whitespace, replace comma with empty string
    const cleanStr = distanceStr.replace(/km/g, "").trim().replace(/,/g, "");
    return parseFloat(cleanStr) || 0;
  };

  const distanceValue = getDistanceValue(distance);

  const fareConstants = {
    महाराष्ट्र: [
      {
        vehicleType: "Taxi Mini",
        fare: distanceValue * 10 * 1.2 + 5,
      },
      {
        vehicleType: "Auto",
        fare: distanceValue * 16 * 1.2 + 50,
      },
      {
        vehicleType: "Sedan",
        fare: distanceValue * 11 * 1.2 + 5,
      },
      {
        vehicleType: "Suv",
        fare: distanceValue * 15.73 * 1.2 + 5,
      },
    ],
    maharastra: [
      {
        vehicleType: "Taxi Mini",
        fare: distanceValue * 10 * 1.2 + 5,
      },
      {
        vehicleType: "Auto",
        fare: distanceValue * 16 * 1.2 + 50,
      },
      {
        vehicleType: "Sedan",
        fare: distanceValue * 11 * 1.2 + 5,
      },
      {
        vehicleType: "Suv",
        fare: distanceValue * 15.73 * 1.2 + 5,
      },
    ],
    jharkhand: [
      {
        vehicleType: "Taxi Mini",
        fare: distanceValue * 38 * 0.5 + 20,
      },
      {
        vehicleType: "Auto",
        fare: distanceValue * 28 * 0.5 + 20,
      },
      {
        vehicleType: "Sedan",
        fare: distanceValue * 40 * 0.5 + 20,
      },
      {
        vehicleType: "Suv",
        fare: distanceValue * 50 * 0.5 + 20,
      },
    ],
  };

  const getFare = (vehicleType) => {
    const regionRates =
      fareConstants[pickUpRegion] || fareConstants["maharastra"];
    const fareData = regionRates.find(
      (fare) => fare.vehicleType === vehicleType
    );
    return Math.ceil(fareData?.fare || 0);
  };

  return (
    <>
      {openLogDialogBox && (
        <div
          style={{
            fontSize: "1.6rem",
          }}
          className={styles.logsBottomModal}
        >
          <div className={styles.modalBackdrop}>
            <div className={styles.visibleModal}>
              <h3 className={styles.modalMainHeading}>{"Approx Fare"}</h3>
              <div className={styles.fareWithVehicle}>
                <div>
                  <p
                    style={{
                      color: "yellow",
                    }}
                  >
                    Taxi Mini
                  </p>
                  <p>{"₹ " + getFare("Taxi Mini")}</p>
                </div>
                <div>
                  <p
                    style={{
                      color: "yellow",
                    }}
                  >
                    {pickUpRegion === "jharkhand" ? "Auto/Tuktuk" : "Auto"}
                  </p>
                  <p>{"₹ " + getFare("Auto")}</p>
                </div>
                <div>
                  <p
                    style={{
                      color: "yellow",
                    }}
                  >
                    Sedan
                  </p>
                  <p>{"₹ " + getFare("Sedan")}</p>
                </div>
                <div>
                  <p
                    style={{
                      color: "yellow",
                    }}
                  >
                    Suv
                  </p>
                  <p>{"₹ " + getFare("Suv")}</p>
                </div>
              </div>

              <p>*Toll extra</p>
              <div className={styles.distanceDurationRow}>
                <p>
                  <span
                    style={{
                      color: "yellow",
                    }}
                  >
                    Distance:
                  </span>{" "}
                  {distance}
                </p>
                <p>
                  <span
                    style={{
                      color: "yellow",
                    }}
                  >
                    Duration:
                  </span>{" "}
                  {duration}
                </p>
              </div>
              <div className={styles.buttonRow}>
                <button
                  onClick={() => {
                    //
                    setIsConfirmationDialogOpen(false);
                  }}
                  className={styles.button}
                >
                  {"Edit"}
                </button>
                <button
                  onClick={() => {
                    // setOpenTaxiTypeModal(false);
                    bookRideButtonHandler();
                  }}
                  className={styles.button}
                >
                  {"Proceed"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
